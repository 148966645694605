import { FC, useCallback, useContext, useEffect, useState } from 'react';
import styles from 'styles/components/LoginSignup.module.scss';
import Image from 'next/image';
import logo from 'public/images/icon128x128.png';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { auth, provider } from 'lib/firebase';
import { mutate } from 'swr';
import { UserContext } from 'lib/context/UserContext';
import { logError } from 'lib/functions/errors';
import axios from 'axios';
import WelcomePanel from './WelcomePanel';

const LoginSignup: FC<{ type: 'login' | 'sign-up' }> = ({ type = 'login' }) => {
	const router = useRouter();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [secondPassword, setSecondPassword] = useState('');
	const [error, setError] = useState('');
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [working, setWorking] = useState(false);
	const { authUser } = useContext(UserContext);

	const submitLogin = useCallback(() => {
		setEmailError(false);
		setPasswordError(false);
		setWorking(true);
		auth.signInWithEmailAndPassword(email, password)
			.then(() => {
				mutate('/api/me');
			})
			.catch((err: any) => {
				setWorking(false);
				if (err.code === 'auth/user-not-found') {
					setError(`There is no account with the email ${email}`);
					setEmailError(true);
				}
				if (err.code === 'auth/invalid-email') {
					setError(`Invalid email`);
					setEmailError(true);
				}
				if (err.code === 'auth/wrong-password') {
					setError(`Your password is incorrect`);
					setPasswordError(true);
				}
			});
	}, [email, password]);

	const submitSignUp = useCallback(() => {
		setEmailError(false);
		setPasswordError(false);
		setWorking(true);
		auth.createUserWithEmailAndPassword(email, password)
			.then((userCredential: any) => {
				// create user in db
				axios
					.post('/api/users', {
						email,
						uid: userCredential.user.uid,
					})
					.then((res) => {
						console.log(res);
						mutate('/api/me');
					})
					.catch((e) => {
						console.log(e);
					});
			})
			.catch((err: any) => {
				if (err.code === 'auth/email-already-in-use') {
					auth.signInWithEmailAndPassword(email, password)
						.then(() => {
							// success
						})
						.catch((e: any) => {
							logError(e);
							setError(`There is already an account associated with ${email}`);
							setEmailError(true);
							setWorking(false);
						});
				}
				if (err.code === 'auth/invalid-email') {
					setError(`There is a problem with your email address`);
					setEmailError(true);
					setWorking(false);
				}
				if (err.code === 'auth/weak-password') {
					setError(`Your password must be at least 6 characters`);
					setPasswordError(true);
					setWorking(false);
				}
			});
	}, [email, password]);

	useEffect(() => {
		if (email) {
			setEmailError(false);
		}
	}, [email]);

	useEffect(() => {
		if (password) {
			setPasswordError(false);
		}
	}, [password]);

	// This hook allows the user to submit the form by pressing the enter key
	useEffect(() => {
		const callback = (e: any) => {
			if (e.key === 'Enter') {
				if (type === 'login') submitLogin();
				if (type === 'sign-up') submitSignUp();
			}
		};

		window.addEventListener('keyup', callback);
		return () => window.removeEventListener('keyup', callback);
	}, [submitLogin, submitSignUp]);

	useEffect(() => {
		if (authUser) {
			router.push('/');
		}
	}, [authUser]);

	return (
		<>
			<WelcomePanel>
				<div
					style={{
						boxShadow: '2px 0 5px rgba(0,0,0,0.15)',
						width: '64px',
						height: '64px',
						borderRadius: '0.5rem',
					}}>
					<Image src={logo} alt='OrderSimply' width={64} height={64} />
				</div>
				{type === 'login' && <h3>Login To OrderSimply</h3>}
				{type === 'sign-up' && <h3>Create An Account</h3>}
				<input
					placeholder='email'
					type='text'
					onChange={(e) => {
						setEmail(e.target.value);
					}}
					value={email}
					className={emailError ? 'error' : undefined}
				/>
				<input
					placeholder='password'
					type='password'
					onChange={(e) => {
						setPassword(e.target.value);
					}}
					value={password}
					className={passwordError ? 'error' : undefined}
				/>
				{type === 'sign-up' && (
					<input
						placeholder='re-enter password'
						type='password'
						onChange={(e) => {
							setSecondPassword(e.target.value);
						}}
						value={secondPassword}
						className={passwordError ? 'error' : undefined}
						data-testid='input__password2'
					/>
				)}
				<p className={styles.error}>{error}</p>
				<button
					className='blueButton'
					disabled={working}
					onClick={type === 'login' ? submitLogin : submitSignUp}>
					{type === 'login' && 'Sign In'}
					{type === 'sign-up' && 'Create Account'}
				</button>
				{error === 'Your password is incorrect' ? (
					<Link href={`/reset-password/${email}`}>
						<a>I Forgot My Password</a>
					</Link>
				) : (
					<>
						{type === 'login' && (
							<Link href='/sign-up'>
								<a>I Don't Have An Account</a>
							</Link>
						)}
						{type === 'sign-up' && (
							<Link href='/login'>
								<a>I Already Have An Account</a>
							</Link>
						)}
					</>
				)}
			</WelcomePanel>
			<div style={{ marginTop: '10px' }}>
				<button
					className={styles.loginWithGoogleButton}
					onClick={() => auth.signInWithRedirect(provider)}
					disabled={working}>
					<div
						className={styles.googleIcon}
						style={{ backgroundImage: `url(/images/google_icon.png)` }}
					/>
					Sign {type === 'login' ? 'In' : 'Up'} With Google
				</button>
			</div>
		</>
	);
};

export default LoginSignup;
