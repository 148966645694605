import { useContext } from 'react';
import { UserContext } from 'lib/context/UserContext';
import OnboardingPage from 'components/Onboarding/OnboardingPage';
import LoginSignup from 'components/Onboarding/LoginSignup';

const Login = () => {
	const { userLoading } = useContext(UserContext);

	return (
		<OnboardingPage title='Login to OrderSimply' isLoading={userLoading}>
			<div className='center-child'>
				<LoginSignup type='login' />
			</div>
		</OnboardingPage>
	);
};

export default Login;
