import { FC, ReactNode } from 'react';
import Page from 'components/Common/Page';
import SmallLoader from 'components/Loader/SmallLoader';
import PageLoader from 'components/Loader/PageLoader';
import styles from 'styles/pages/Onboarding.module.scss';

const OnboardingPage: FC<{ title: string; isLoading?: boolean; children?: ReactNode }> = ({
	title,
	isLoading = false,
	children,
}) => {
	if (isLoading) {
		return <PageLoader />;
	}
	return (
		<Page className={styles.container} fade title={title} hideFooter hideNavbar>
			{isLoading ? (
				<>
					<SmallLoader />
				</>
			) : (
				<div className={styles.content}>{children}</div>
			)}
		</Page>
	);
};

export default OnboardingPage;
